import { graphql } from 'gatsby';
import React, { FunctionComponent, ReactElement } from 'react';
import FlexibleContent from '~/components/FlexibleContent';
import SEO from '~/components/Global/SEO';
import Layout from '~/components/Layout';
import { FlexTemplatePageQuery } from '../graphql-types';

interface FlexTemplateProps {
  data: FlexTemplatePageQuery;
}

const FlexTemplateFC: FunctionComponent<FlexTemplateProps> = ({
  data,
}): ReactElement => {
  const { title, layouts } = data.wpPage;

  return (
    <Layout>
      <SEO title={title} />
      <main>
        <FlexibleContent pageName="Flexible Template" layouts={layouts} />
      </main>
    </Layout>
  );
};

export default FlexTemplateFC;

export const query = graphql`
  query FlexTemplatePage($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      layouts {
        layouts {
          __typename
          ... on WpPage_Layouts_Layouts_TwoColImageText {
            subheading
            heading
            headingWidth
            content
            contentWidth
            image {
              ...Image
            }
            imageType
            animationName
            imagePosition
            backgroundColour
            # alertNotice
            # noticeContent
            contentLink
            link {
              title
              url
            }
          }
          ... on WpPage_Layouts_Layouts_SecurityPanel {
            icon {
              ...Image
            }
            heading
            content
            # link {
            #   title
            #   url
            # }
          }
          #... on WpPage_Layouts_Layouts_donations_purpose {
          # id
          # heading
          # content
          # animation {
          #   source_url
          #   localFile {
          #     id
          #     url
          #   }
          # }
          #}
          ... on WpPage_Layouts_Layouts_ValidationHero {
            heroContent {
              subheading
              headingStart
              changeWords {
                word
              }
              headingEnd
              content
              button {
                title
                url
              }
              image {
                ...Image
              }
              icon {
                ...Image
              }
              logos {
                logo {
                  ...Image
                }
              }
            }
          }
          ... on WpPage_Layouts_Layouts_GlobeAnimation {
            animationContent {
              subheading
              heading
              content
              button {
                title
                url
              }
              image {
                ...Image
              }
            }
          }
          ... on WpPage_Layouts_Layouts_DonationsBeforeAfter {
            heading
            content
            noticeContent
            tab {
              tabName
            }
          }
          ... on WpPage_Layouts_Layouts_TestiomnialSlider {
            testimonials {
              name
              role
              quote
              logo {
                ...Image
              }
            }
          }
          ... on WpPage_Layouts_Layouts_RelatedPages {
            layout
            subheading
            heading
            content
            page {
              heading
              description
              link {
                title
                url
              }
              image {
                ...Image
              }
            }
          }
          ... on WpPage_Layouts_Layouts_FeaturesContentGrid {
            column {
              icon {
                ...Image
              }
              heading
              content
            }
            backgroundColour
            columns
          }
          ... on WpPage_Layouts_Layouts_UseCasesGrid {
            subheading
            heading
            content
            link {
              title
              url
            }
          }
          ... on WpPage_Layouts_Layouts_TwoColTextAndFullWidthImage {
            subheading
            heading
            content
            image {
              ...Image
            }
            backgroundColour
          }
          ... on WpPage_Layouts_Layouts_Cta {
            heading
            content
            button {
              title
              url
            }
            image {
              ...Image
            }
            mobileImage {
              ...Image
            }
            imagePosition
          }
          ... on WpPage_Layouts_Layouts_LandingHero {
            heading
            content
            button {
              title
              target
              url
            }
            topLogo {
              ...Image
            }
            bottomLogo {
              ...Image
            }
            bottomLogoCaption
            imageGallery {
              image {
                ...Image
              }
            }
          }
          ... on WpPage_Layouts_Layouts_TwoCardsContent {
            heading
            content
            cards {
              icon {
                ...Image
              }
              heading
              description
            }
          }
          ... on WpPage_Layouts_Layouts_PricingMatrix {
            heading
            content
            cards {
              color
              icon {
                ...Image
              }
              location
              cardLabel
              rates {
                stat
                preappend
                addOptionalLabel
                label
              }
              information {
                heading
                smallHeading
                description
                stat
              }
              addTerms
              terms
            }
            informationIcon {
              ...Image
            }
            informationHeading
            informationParagraphOne
            informationParagraphTwo
          }
          ... on WpPage_Layouts_Layouts_TwoColImageText {
            subheading
            heading
            headingWidth
            content
            contentWidth
            image {
              ...Image
            }
            imagePosition
            backgroundColour
            contentLink
            link {
              title
              url
            }
          }
          ... on WpPage_Layouts_Layouts_TwoColHeroBleed {
            heading
            content
            link {
              title
              url
            }
            image {
              ...Image
            }
          }
          ... on WpPage_Layouts_Layouts_IconBoxes {
            heading
            content
            iconBoxes {
              icon {
                ...Image
              }
              heading
              lists {
                listItem
              }
            }
          }
          ... on WpPage_Layouts_Layouts_PurposeStack {
            background
            subheading
            heading
            content
            featureBox {
              heading
              content
            }
          }
          ... on WpPage_Layouts_Layouts_CardSlider {
            subheading
            heading
            cards {
              image {
                ...Image
              }
              heading
              content
              link {
                title
                url
              }
            }
          }
          ... on WpPage_Layouts_Layouts_IconColumn {
            subheading
            heading
            cards {
              icon {
                ...Image
              }
              heading
              content
            }
          }
          ... on WpPage_Layouts_Layouts_AltCta {
            heading
            content
            link {
              title
              url
            }
          }
          ... on WpPage_Layouts_Layouts_SimpleTextCard {
            subheading
            heading
            content
            link {
              title
              url
            }
            background
            headingWidth
          }
          ... on WpPage_Layouts_Layouts_TwoColumnSimplePricing {
            heading
            cards {
              subheading
              heading
              description
              link {
                title
                url
              }
              caption
            }
          }
          ... on WpPage_Layouts_Layouts_PercentAdvisorCard {
            image {
              ...Image
            }
            heading
            description
            link {
              title
              url
            }
          }
          ... on WpPage_Layouts_Layouts_Faqs {
            heading
            faqs {
              question
              answer
            }
          }
          ... on WpPage_Layouts_Layouts_LinksList {
            heading
            links {
              link {
                title
                url
                target
              }
            }
          }
          ... on WpPage_Layouts_Layouts_InlineForm {
            contentLeft {
              subheading
              heading
              content
            }
            thankYouMessage {
              heading
              content
              image {
                ...Image
              }
            }
            formBackgroundImage {
              ...Image
            }
          }
        }
      }
    }
  }
`;
